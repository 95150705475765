
import React, { Fragment, useState, useRef, useEffect } from 'react';
import { getCookie } from '../../utils/cookies';
import tutorilaimage from '../../assets/tutorial.jpg';
import './tutorials.css';
import ReactPlayer from 'react-player'

import back from "../../assets/back.jpeg"


import { useNavigate } from "react-router-dom";

const Tutorials = () => {

  let navigate = useNavigate();

  useEffect(() => {
    const email = getCookie('_email')
    if(email == '' || email == undefined){
        navigate(`/`);
    }
  });

  const goToWebShop = () => {
    let path = `/Web_Shop`;
    navigate(path);
    window.scrollTo(0, 0);
  }



  return (
    <Fragment>
      {
        <div className='tutorial_container'>
          <div className='tutorials_body'>
            <div className='tutorials_title'>
              <h1>Intrinsic Tutorials</h1>
            </div>
            <div className='tutorials'>
              <div className='tutorials_video_row'>
                <div className='att_video_column'>
                  <ReactPlayer
                    className="att_video"
                    url={"https://youtu.be/b5FmLJiE40U"}
                    controls={true}
                  />
                </div>
                <div className='att_video_column'>
                  <h2>Diffusers with a Cell Phone</h2>
                </div>
              </div>
              <div className='tutorials_video_row'>
                <div className='att_video_column'>
                  <ReactPlayer
                    className="att_video"
                    url={"https://youtu.be/TbEa1cUb6CY"}
                    controls={true}
                  />
                </div>
                <div className='att_video_column'>
                  <h2>Diffusers with a Camera</h2>
                </div>
              </div>
              <div className='tutorials_video_row'>
                <div className='att_video_column'>
                  <ReactPlayer
                    className="att_video"
                    url={"https://youtu.be/UHvNh1GjBvc"}
                    controls={true}
                  />
                </div>
                <div className='att_video_column'>
                  <h2>Diffusers with a Microscope</h2>
                </div>
              </div>
              <div className='tutorials_video_row'>
                <div className='att_video_column'>
                  <ReactPlayer
                    className="att_video"
                    url={"https://youtu.be/gqWCX5S_IsI"}
                    controls={true}
                  />
                </div>
                <div className='att_video_column'>
                  <h2>Processing Image Pairs</h2>
                </div>
              </div>
              <div className='tutorials_video_row'>
                <div className='att_video_column'>
                  <ReactPlayer
                    className="att_video"
                    url={"https://youtu.be/9wkh3RmL0S0"}
                    controls={true}
                  />
                </div>
                <div className='att_video_column'>
                  <h2>Qualiti Assurance Evaluator</h2>
                </div>
              </div>
             
             
            </div>
          </div>
        </div>

      }
    </Fragment>
  )
}

export default Tutorials
