import React, { useState, useRef, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { BiUser } from "react-icons/bi";
import { NavLink, Link } from 'react-router-dom';
import { getCookie, setCookie } from '../../utils/cookies';
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import shoping_cart from "./shoping_cart.png"

import { userData } from '../../api/api'
import { RequestAPI } from '../../utils/request-api'


import './navbar.css';
import logo from './patology.png';
import upr from './upr.png';


const Navbar = ({ orders, widChanger, signedIn, userName, userEmail, signOut }) => {

  const getWindowsDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [toggleMenu, setToggleMenu] = useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [signin, setSignIn] = useState(false)
  const [wid, setWid] = useState(getWindowsDimensions());
  const boxRef = useRef();
  const [vari, setVari] = useState(true);
  const [navigation, setNavigation] = useState([
    { name: 'Home', href: '/' },
    { name: 'Tutorials', href: '/Process_Tutorials' },
    { name: 'Image Processing', href: '/Processing' },
    { name: 'QAE', href: '/AdminSubbmissions' },
    { name: 'Terms of use', href: '/Terms' },
    { name: 'Image Viewer', href: '/ImagePresenter' }
  ]);

  function useOutsideAlerter(boxRef, toggleMenu) {
    useEffect(() => {

      function handleClickOutside(event) {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
          setToggleMenu(false);
        }
      }
      if (toggleMenu) {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {

          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    },);
  }


  const goToSignIn = () => {
    let path = `/Login`;
    navigate(path);
  }

  useEffect(() => {
    const handleResize = () => {
      setWid(getWindowsDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const _name = getCookie('_name');
    const _email = getCookie('_email');
    if (_email !== " " && _email !== "" && _email != null) {
      setName(_name)
      setEmail(_email)
      setSignIn(true)

      fetchData()
    }

  }, [name], [signin]);


  const fetchData = async () => {
    try {
      const email = getCookie('_email')
      const body = {
        email: email
      }
      const response = await RequestAPI(userData(body));

      if (response.status === 200) {
        if (response.data.admin != undefined && response.data.admin != null && response.data.admin == true) {
          setNavigation([
            { name: 'Home', href: '/' },
            { name: 'Tutorials', href: '/Process_Tutorials' },
            { name: 'Image Processing', href: '/Processing' },
            { name: 'QAE', href: '/AdminSubbmissions' },
            { name: 'Terms of use', href: '/Terms' },
            { name: 'Image Viewer', href: '/ImagePresenter' }
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  const logOut = () => {
    setSignIn(false);
    setCookie('_jwt', " ");
    setCookie('_name', " ");
    setName(" ");
    signOut();
  }

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  }

  const changeR = () => {
    let path = `/`;
    const _name = getCookie('_name');
    const _email = getCookie('_email');
    if (_name !== " ") {
      setName(_name)
      setEmail(_email)
      setSignIn(true)
    }
    navigate(path);
  }

  const goToProfil = () => {
    let path = `/My_Profile`;
    navigate(path);
  }

  setTimeout(() => {
    setVari(false);
  }, 0)

  return (
    <>
      <div className='cqc__navbar_second'>
        <div className='cqc__navbar-links_container'>
          <p><NavLink to='/'>
            <div className='abbvie_logo'>
              <img src={logo} alt='img' />
            </div>
          </NavLink></p>
        </div>
        {navigation.map((item) => (
          <p>
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) => {
                return (
                  (!isActive
                    ? 'cqc__navbar-links_container'
                    : 'cqc__navbar-links_container2'
                  )
                );
              }}
            >
              {item.name}
            </NavLink>
          </p>
        ))}
        {signedIn &&
           <p>
           <NavLink
             key={"Web Shop"}
             to={"/Web_Shop"}
             className={({ isActive }) => {
               return (
                 (!isActive
                   ? 'cqc__navbar-links_container'
                   : 'cqc__navbar-links_container2'
                 )
               );
             }}
           >
             {"Web Shop"}
           </NavLink>
         </p>
        }
        {signedIn ?
          <p>
            <NavLink
              key={userName}
              to={"/My_Profile"}
              className={({ isActive }) => {
                return (
                  (!isActive
                    ? 'cqc__navbar-links_container'
                    : 'cqc__navbar-links_container2'
                  )
                );
              }}
            >
              {userName}
            </NavLink>
          </p>
          :
          <p>
            <button type='button' className='button1' onClick={goToSignIn}>Sign In</button>
          </p>
        }
        <div className='cqc__navbar-links_container'>
          <p><NavLink to='/'>
            <div className='abbvie_logo'>
              <img src={upr} alt='img' />
            </div>
          </NavLink></p>
        </div>
      </div>
    </>
  )
}

export default Navbar
